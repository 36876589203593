import React, { ReactElement } from 'react';
import { Typography, Card, CardContent, CardMedia } from '@mui/material';

interface BlogDetailProps {
  title: string;
  content: string | ReactElement;
  imageUrl?: string;
  date: string;
}

export const BlogDetail: React.FC<BlogDetailProps> = ({ title, content, imageUrl, date }) => {
  return (
    <Card sx={{ maxWidth: 800, margin: 'auto', marginBottom: 4 }}>
      {imageUrl && (
        <CardMedia
          component="img"
          height="400"
          image={imageUrl}
          alt={title}
        />
      )}
      <CardContent>
        <Typography gutterBottom variant='h5'>
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {date}
        </Typography>
        <Typography variant="body1" component="p">
          {content}
        </Typography>
      </CardContent>
    </Card>
  );
};

import { GameDescriptionProps } from "../../types";

export const videogamesList: GameDescriptionProps[] = [
    {
        title: "Overcooked!  All you can eat",
        description: "Great friends and family game, where you can play on local or online to create the best and funny dishes as you go throug all of the worlds from Overcooked 1 and 2, plus the secre levels. If you want to save the world from Giant Meatball monster or the Zombie Breads to just play casually for fun thisis a MUST HAVE game if you have kids.",
        imageUrl: "overcooked.jpg",
        category: "Family",
        isMultiplayer: true,
        isSplitScreen: true,
        nintendoLink: "https://www.nintendo.com/us/store/products/overcooked-all-you-can-eat-switch",
        playstationLink: "https://www.playstation.com/en-us/games/overcooked--all-you-can-eat/",
        xboxLink: "https://www.xbox.com/en-US/games/store/overcooked-all-you-can-eat/9PP2NCZ72C79"
    },
    {
        title: "Astro Bot",
        description: "Join Astro Bot, a cute robot, on a thrilling rescue mission! Explore colorful worlds, solve puzzles, and defeat tricky enemies. With your DualSense controller, you can reach out and grab objects, swing through the air, and even tickle your robotic friends! Get ready for a fun-filled adventure that's perfect for the whole family",
        imageUrl: "astro.jpeg",
        category: "Family",
        isMultiplayer: false,
        isSplitScreen: false,
        nintendoLink: "",
        playstationLink: "https://www.playstation.com/en-us/games/astro-bot/",
        xboxLink: ""
    },
    {
        title: "Ghostbusters: Spirits Unleashed",
        description: "Great Ghostbusters' game for fans and kids who enjoy catching ghosts. This game you can be the classical ghostbuster or a ghost!! You can play with friends to help you in multiplayer and it is cross-play. There is no split-screen but the kids enjoy the solo gameplay too.",
        imageUrl: "EGS_GhostbustersSpiritsUnleashed_IllFonic.png",
        category: "Action",
        isMultiplayer: true,
        isSplitScreen: false,
        nintendoLink: "https://www.nintendo.com/us/store/products/ghostbusters-spirits-unleashed-ecto-edition-switch",
        playstationLink: "https://www.playstation.com/en-us/games/ghostbusters-spirits-unleashed/",
        xboxLink: "https://www.xbox.com/en-US/games/store/ghostbusters-spirits-unleashed-ecto-edition/9PHJC0H6HPKD"
    },
];

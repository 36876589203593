import { Box, Skeleton, useMediaQuery, useTheme } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { youTubeContainerSkeleton, youTubeContainerSkeletonXs } from '../../types';

interface YouTubeProps {
  videoId: string;
}

const YouTube: React.FC<YouTubeProps> = ({ videoId }) => {
  const [isLoading, setIsLoading] = useState(true);
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    setIsLoading(true);
  }, [videoId]);

  const onLoad = () => {
    setIsLoading(false);
  };

  return (
    <Box>
      {isLoading && <Skeleton variant='rectangular' sx={{
        width: youTubeContainerSkeleton.width,
        height: youTubeContainerSkeleton.height,
        [theme.breakpoints.down("sm")]: {
          height: youTubeContainerSkeletonXs.height,
          width: youTubeContainerSkeletonXs.width,
        }
      }} />}
      <iframe
        src={`https://www.youtube.com/embed/${videoId}`}
        frameBorder="0"
        allow="autoplay; encrypted-media"
        allowFullScreen
        onLoad={onLoad}
        style={{ display: isLoading ? 'none' : 'block' }}
        height={matchesXs ? youTubeContainerSkeletonXs.height : youTubeContainerSkeleton.height}
        width={matchesXs ? youTubeContainerSkeletonXs.width : youTubeContainerSkeleton.width}
      />
    </Box>
  );
};

export default YouTube;

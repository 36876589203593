import { Box, Grow, Link, Paper, SxProps, Typography, useMediaQuery, useTheme } from '@mui/material';
import mw3Image from "../assets/Videogames/MWIII-REVEAL-FULL-TOUT.png";
import eldeRingImage from "../assets/Videogames/elden-ring-new-header-mobile.png";
import ffviiImage from "../assets/Videogames/final-fantasy-vii-rebirth-game-ky.png";
import gowRagnarokImage from "../assets/Videogames/wallpaper_god_of_war_ragnarok.png";
import { GameImage } from '../components/Shared/GameImage';
import TwitchIcon from "../assets/Videogames/twitch-tile.svg";


export const MainPage = () => {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down("md"));
  const matchesMd = useMediaQuery(theme.breakpoints.down("lg"));
  const boxStyle: SxProps = {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    gap: "2rem",
    marginTop: "5.5rem",
    padding: "1.5rem",
    [theme.breakpoints.down("sm")]: {
      marginTop: "2.5rem",
    }
  };

  const imagesSize = matchesSm ? { height: '12.5rem', width: '18.75rem' } : { height: '18.75rem', width: '25rem' };
  const sectionStyle: React.CSSProperties = {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    flexShrink: 1,
    flexWrap: "wrap",
    gap: "1rem",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      FormatAlignJustify: "center",
      flexShrink: 1,
      flexDirection: "column",
    },
    padding: "1.5rem",
    width: "100%",

  };
  const imageStyle: React.CSSProperties = {
    ...imagesSize,
    objectFit: 'cover'
  };


  return (
    <Box>
      <Box sx={{ ...boxStyle }}>

        <Box sx={{
          alignItems: "center",
          borderRadius: "0.5rem",
          color: "#fff",
          display: "flex",
          flexDirection: matchesMd ? "column" : "row",
          flexGrow: 1,
          flexWrap: "wrap",
          gap: "2rem",
          justifyContent: "space-between",
          padding: "2rem",
          [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            height: "auto",
            padding: theme.spacing(2),
            gap: 0,
          },
          fontSize: matchesSm ? "2.75rem" : "3.75rem",
        }}>

          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "1rem" }}>
            <Typography variant="h2"
              sx={{
                alignSelf: "center",
                fontFamily: "Noto Sans, sans-serif",
                fontSize: matchesSm ? "2.75rem" : "3.75rem"
              }}>Average Braves</Typography>
            <Typography variant="body1">Family who likes video games and movies.</Typography>

            <Link href={"https://www.twitch.tv/cob3x85"} target="_blank" rel="noopener noreferrer">

              <img src={TwitchIcon} alt="Twitch" height={100} width={100} />
            </Link>

          </Box>
          {/* <Box sx={{
            display: "flex", flexDirection: "column",
            alignItems: "center",
            [theme.breakpoints.down("md")]: {
              marginTop: "1rem",
              fontSize: matchesSm ? "2.75rem" : "3.75rem"
            }
          }}>
            <Typography variant="h5">Who we are?</Typography>
            <Stack direction="row" spacing={2} sx={{ marginTop: "1rem" }}>
              <Avatar alt="Carlos" src="/static/images/avatar/1.jpg" />
              <Avatar alt="Wiwis" src="/static/images/avatar/2.jpg" />
              <Avatar alt="Gerry" src="/static/images/avatar/3.jpg" />
              <Avatar alt="Aser" src="/static/images/avatar/3.jpg" />
              <Avatar alt="Vladi" src="/static/images/avatar/3.jpg" />
            </Stack>
          </Box> */}
        </Box>
        <Grow in timeout={3000}>
          <Box sx={{ ...sectionStyle }}>
            <GameImage sx={{ ...imageStyle }} src={eldeRingImage} alt="Elden Ring Image" />
            <GameImage sx={{ ...imageStyle }} src={gowRagnarokImage} alt="God of War Ragnarok Image" />
            <GameImage sx={{ ...imageStyle }} src={mw3Image} alt="Call of Duty MW3 Image" />
            <GameImage sx={{ ...imageStyle }} src={ffviiImage} alt="Final Fntasy VII Image" />
          </Box>
        </Grow>
        <Paper sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "2rem",
          [theme.breakpoints.down("md")]: {
            padding: theme.spacing(2),
          }
        }}>

          <Typography variant="h3" >More sections coming...</Typography>
        </Paper>

      </Box>
    </Box>
  );
};

import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import React from 'react';
import { GameDescriptionProps, VIDEOGAMES_IMAGES_PATH } from '../../types';
import { GamePurchaseLinks } from './GamePurchaseLinks';

export const GameDescription: React.FC<GameDescriptionProps> = ({
  category,
  description,
  imageUrl,
  isMultiplayer,
  isSplitScreen,
  nintendoLink,
  playstationLink,
  title,
  xboxLink
}) => {
  return (
    <Card sx={{ maxWidth: 600, margin: 'auto', marginBottom: 4 }}>
      <CardMedia
        component="img"
        height="300"
        image={`${VIDEOGAMES_IMAGES_PATH}${imageUrl}`}
        alt={title}
      />
      <CardContent sx={{
        gap: 2,
        display: "flex",
        flexDirection: "column"
      }}>
        <Typography gutterBottom variant="h5" component="div">
          {title}
        </Typography>
        <Typography variant="body2">
          {description}
        </Typography>
        <Typography variant="body2">
          Category: {category}
        </Typography>
        <Typography variant="body2">
          Multiplayer: {isMultiplayer ? 'Yes' : 'No'}
        </Typography>
        <Typography variant="body2">
          Split-Screen: {isSplitScreen ? 'Yes' : 'No'}
        </Typography>
        <GamePurchaseLinks nintendoLink={nintendoLink} playstationLink={playstationLink} xboxLink={xboxLink} />
      </CardContent>
    </Card>
  );
};

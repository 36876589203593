import { Container, Typography } from '@mui/material';

export const ComingSoon = () => {
    return (
        <Container maxWidth="sm" sx={{
            backgroundColor: "white",
            borderRadius: "8px",
            padding: "20px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            marginTop: "7rem",
            marginBottom: "7rem",
            flexDirection: "column",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 3

        }}>
            <Typography variant="h2" >Coming Soon</Typography>


        </Container>
    );
};

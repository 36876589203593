import { FC } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXbox, faPlaystation, } from '@fortawesome/free-brands-svg-icons';
import nintendoLogo from '../../assets/Videogames/nintendo-logo.svg';

type GamePurchaseLinksProps = {
    nintendoLink: string;
    playstationLink: string;
    xboxLink: string;
};

export const GamePurchaseLinks: FC<GamePurchaseLinksProps> = ({ nintendoLink, playstationLink, xboxLink }) => {
    return (
        <Box display="flex" justifyContent="center" alignItems="center">
            <Typography variant="body2">
                Buy Here
            </Typography>
            <Box ml={2} display="flex" alignItems="center">
                {xboxLink !== "" &&
                    <IconButton href={xboxLink} target="_blank">
                        <FontAwesomeIcon icon={faXbox} />
                    </IconButton>
                }
                {playstationLink !== "" &&
                    <IconButton href={playstationLink} target="_blank">
                        <FontAwesomeIcon icon={faPlaystation} />
                    </IconButton>
                }
                {nintendoLink !== "" &&
                    <IconButton href={nintendoLink} target="_blank">
                        <img src={nintendoLogo} alt="Nintendo Logo" style={{ width: '24px', height: '24px' }} />
                    </IconButton>
                }
            </Box>
        </Box>
    );
};

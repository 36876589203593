import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Box, Container, IconButton, Menu, Toolbar, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import logo from "../../assets/average-braves-logo.png";
import useABStore from "../../hooks/useAvBStore";
import { MenuItemLinks } from './MenuItemLinks';
import { useHorizontalScreen } from '../../hooks';

const linkStyles: React.CSSProperties = {
  textDecoration: "none",
  color: "inherit",
};

type PageName = "home" | "games" | "movies" | "howto" | "news";

export type Pages = {
  key: PageName;
  order: number;
  title: string;
};

export const Header = () => {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down('md'));
  const { activeMenu, updateActiveMenu } = useABStore();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { isHorizontal } = useHorizontalScreen();


  const pages: Pages[] = [
    {
      order: 1,
      title: "Home",
      key: "home"
    },
    {
      order: 2,
      title: "Games",
      key: "games"
    },

    {
      order: 3,
      title: "Movies",
      key: "movies"
    },

    {
      order: 4,
      title: "How To",
      key: "howto"
    },
    {
      order: 5,
      title: "News",
      key: "news"
    }
  ];

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (location.pathname === "/") {
      updateActiveMenu("home");
    }
    else {
      updateActiveMenu(location.pathname.toLowerCase().split("/")[1]);
    }
  }, [location, updateActiveMenu]);

  return (
    <AppBar position="fixed" sx={{
      backgroundColor: "#011D33",
      color: theme.palette.primary.contrastText,
      [theme.breakpoints.down('md')]: {
        height: "3.438rem"
      }
    }}>
      <Container maxWidth="xl" sx={{
        ml: 0,
        pr: 0,
        display: "flex",
        justifyContent: "space-between",
      }}>
        <Toolbar disableGutters>
          <Box sx={{ display: { xs: 'none', sm: "flex", md: 'flex' } }}>
            <img src={logo} alt="" style={{
              width: matchesSm && isHorizontal ? 50 : 100,
              height: matchesSm && isHorizontal ? 50 : 100,
            }} />
          </Box>
          <Box sx={{ display: { xs: 'flex', sm: "none", md: 'none' } }}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleClick}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              // MenuListProps={{
              //   'aria-labelledby': 'basic-button',

              // }}
              disableScrollLock
              sx={{
                display: { xs: 'block', sm: "none", md: 'none' },

                "& .MuiPaper-root": {
                  backgroundColor: "#093150",
                  color: theme.palette.primary.contrastText,
                }
              }}
            >
              <MenuItemLinks pages={pages} activeMenu={activeMenu} linkStyles={{ ...linkStyles, backgroundColor: "#093150" }} isMobileMenu handleClose={handleClose} />
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', sm: "flex", md: 'flex' } }}>
            <MenuItemLinks pages={pages} activeMenu={activeMenu} linkStyles={linkStyles} />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
